var dispatch = {
	lockBody: function() {
		$('body').addClass('lockScroll');
		$('body').css({ 'paddingRight': getScrollBarWidth() });
	},

	unlockBody: function() {
		$('body').removeClass('lockScroll');
		$('body').css({ 'paddingRight': 0 });
	},

	forcusEffect: function () {
		if (!String.prototype.trim) {
			(function () {
				var c = /^[\s\uFEFF\xA0]+|[\s\uFEFF\xA0]+$/g;
				String.prototype.trim = function () {
					return this.replace(c, "")
				}
			})()
		}

		[].slice.call(document.querySelectorAll(".input__field")).forEach(function (c) {
			if (c.value.trim() !== "") {
				c.parentNode.classList.add('input--filled');
			}
			c.addEventListener("focus", a);
			c.addEventListener("blur", b)
		});

		function a(e) {
			e.target.parentNode.classList.add('input--filled');
		}

		function b(e) {
			if (e.target.value.trim() === "") {
				e.target.parentNode.classList.remove('input--filled');
			}
		}
	},

	common: function () {
		var scrollTop = parseInt($(window).scrollTop());
		if (scrollTop !== 0) {
			$("#mainHeader").addClass("sticky");
			$('#backtoTop').show();
		}
		$(window).scroll(function () {
			var scrollTop = parseInt($(window).scrollTop());
			if (scrollTop > 0) {
				$("#mainHeader").addClass("sticky");
				$('#backtoTop').show();
			} else {
				$("#mainHeader").removeClass("sticky");
				$("#mainHeader").removeAttr("style");
				$('#backtoTop').hide();
			}
		});

		$('#backtoTop').click(function () {
			$('body, html').animate({ scrollTop: 0 }, 600);
		});

		// Scroll Height Calculator
		$(window).scroll(function () {
			var wintop = $(window).scrollTop(), docheight = $('html').height(), winheight = $(window).height();
			var totalScroll = (wintop / (docheight - winheight)) * 100;
			$(".scrollAnimate").css("width", totalScroll + "%");
		});

		// For bootstrap Modals only
		(function () {
			var isForgotModal = false;

			$('.open_OTP_CreateAccount').click(function () {
				$('.modal').modal('hide');
				isForgotModal = true;
			});

			// Default Modal Show Function
			$('.modal').on('show.bs.modal', function () {
				$('#mainHeader').css({ 'right': getScrollBarWidth() })
			});

			// Default Modal Hide Function
			$('.modal').on('hidden.bs.modal', function (e) {
				$('#mainHeader').css({ 'right': 0 });

				if (isForgotModal) {
					$('#modal_OTP_CreateAccount').modal('show');
				}
				isForgotModal = false;
			});
		})();

		// Toggle view of Filter box
		(function () {
			$('[data-filterView="toggle"]').click(function () {
				var target = this.dataset.targetid;
				this.className += ' show';

				if (this.textContent == 'Show Filter') {
					document.querySelector(target).closest('.showCaseBox').className += ' is-filterShow';
					this.textContent = 'Hide Filter';
					this.classList.remove('show');
				} else {
					document.querySelector(target).closest('.showCaseBox').classList.remove('is-filterShow');
					this.textContent = 'Show Filter';
					this.classList.add('show');
				}
			});
		})();

		(function () {
			if ($('.searchHandle')) {
				function closeSearch() {
					$('.header__logo, .primaryMenu, .headerLinks').removeClass('fadeOut');
					$('.searchBar').removeClass('fadeIn');
					$('.header__overlay').fadeOut(500);
					$('.autoSuggestion ul').removeClass('active');
				}

				$('.searchHandle').click(function () {
					$('.header__logo, .headerLinks, .primaryMenu').addClass('fadeOut');
					$('.searchBar').addClass('fadeIn');
					$('.header__overlay').fadeIn(500);
					$('#searchInput').focus();
					$('.autoSuggestion ul').addClass('active');
				});
				$('#searchClose, .header__overlay').click(function () {
					closeSearch();
				});

				$(document).on('keyup', function (e) {
					if (e.keyCode === 27 && e.which === 27) {
						closeSearch();
					}
				})
			}
		})();

		// Mobile Menu
		$('.menuHandle').click(function (e) {
			e.preventDefault();
			$(this).find('img').attr('src', function (index, attr) {
				var newAttr = attr.split('/');
				var curImg = newAttr[newAttr.length - 1].split('.')[0];
				var path = newAttr.slice(0, newAttr.length - 1).join('/');

				if (curImg == 'menu') {
					$(this).closest('.primaryMenu').find('#toggleMenu').slideDown(400);
					return path + '/close.svg'
				} else {
					$(this).closest('.primaryMenu').find('#toggleMenu').slideUp(300);
					return path + '/menu.svg'
				}
			});
		});

		// Mobile dropdown toggle
		$('.dropdown-handle').click(function () {
			var siblings = $(this).parents('li').siblings();
			siblings.find('.dropdown').slideUp();
			siblings.find('.dropdown-handle').removeClass('active');
			$(this).parents('li').find('.dropdown').slideToggle();
			$(this).toggleClass('active');
		});

		// Dashboard Sidebar toggle
		$('.dash-toggle').click(function () {
			$(this).parent('.userDashboard__leftNav').toggleClass("open");
			$('.footer').toggleClass("dash-opened");
		});
	},

	homePage: function () {
		$('#bannerSlider').slick({
			dots: true,
			arrows: false,
			speed: 500,
			autoplay: true,
			autoplaySpeed: 5000,
			infinite: true,
			slidesToShow: 1,
			slidesToScroll: 1,
		});

		$('#testimonialSlider').slick({
			dots: true,
			arrows: false,
			speed: 500,
			autoplay: true,
			autoplaySpeed: 5000,
			infinite: true,
			slidesToShow: 1,
			slidesToScroll: 1,
		});
		
		$('#atheletSlider').slick({
			dots: true,
			arrows: false,
			speed: 500,
			autoplay: true,
			autoplaySpeed: 5000,
			infinite: true,
			slidesToShow: 1,
			slidesToScroll: 1,
		});
		$('#ourExperience').slick({
			dots: false,
			arrows: false,
			speed: 500,
			autoplay: true,
			autoplaySpeed: 5000,
			infinite: true,
			slidesToShow: 4,
			slidesToScroll: 1,
			responsive: [
				{
				  breakpoint: 1024,
				  settings: {
					slidesToShow: 3,
					slidesToScroll: 1,
					infinite: true,
					dots: false
				  }
				},
				{
				  breakpoint: 600,
				  settings: {
					slidesToShow: 2,
					slidesToScroll: 1
				  }
				},
				{
				  breakpoint: 480,
				  arrows: true,
				  settings: {
					slidesToShow: 1,
					slidesToScroll: 1
				  }
				}
			  ]
		});

		$('#upcomingEventsSlider').slick({
			dots: false,
			arrows: true,
			prevArrow: "<button type='button' class='slick-prev'><img src='img/upcomingEvents/arrow-prev.png' alt=''></button>",
			nextArrow: "<button type='button' class='slick-next'><img src='img/upcomingEvents/arrow-next.png' alt=''></button>",
			speed: 500,
			autoplay: true,
			autoplaySpeed: 5000,
			infinite: true,
			slidesToShow: 3,
			slidesToScroll: 1,
			responsive: [
				{
				  breakpoint: 1024,
				  settings: {
					slidesToShow: 2,
					slidesToScroll: 1,
					infinite: true,
					dots: false
				  }
				},
				{
				  breakpoint: 600,
				  settings: {
					slidesToShow: 2,
					slidesToScroll: 1
				  }
				},
				{
				  breakpoint: 480,
				  settings: {
					slidesToShow: 1,
					slidesToScroll: 1
				  }
				}
			  ]
		});

		$('#ourAthletesSlider').slick({
			dots: true,
			arrows: false,
			speed: 500,
			autoplay: true,
			autoplaySpeed: 5000,
			infinite: true,
			slidesToShow: 1,
			slidesToScroll: 1,
		});

		$("#searchHandle").click(function() {
			$(".searchBox").addClass("show");
		});

		$("#closeSearchBox").click(function() {
			$(".searchBox").removeClass("show");
		});

        $(document).on('click', 'a[href^="#"]', function (event) {
            event.preventDefault();

            $('html, body').animate({
                scrollTop: $($.attr(this, 'href')).offset().top - 100
            }, 500);
		});
	},

	init: function () {
		this.forcusEffect();
		this.common();
		this.homePage();
	}
};

$(document).ready(() => {
	$('body').addClass('lockScroll');
	$('body').css({ 'paddingRight': getScrollBarWidth() });
	dispatch.init();

	$(window).load(() => {
		if (!document.querySelector('.siteLoaderWrap')) return;
		setTimeout(() => {
			$('body').removeClass('lockScroll');
			$('body').css({ 'paddingRight': 0 });
			$('.siteLoaderWrap').hide();
		}, 0);
	});
});


// mobile menu

$('.heamBurger').click(function(e) {
	e.stopPropagation();
	$('.mobileMenuWrapper').addClass('showMenu');
});

$('.close-menu').click(function() {
	$('.mobileMenuWrapper').removeClass('showMenu');
});

$('body').click(function(e) {
	$('.mobileMenuWrapper').removeClass('showMenu');
});

$('.mobileMenuWrapper').click(function(e) {
	e.stopPropagation()
});